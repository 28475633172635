export enum EFunnels {
  course_booking = 'course_booking',
  free_lesson = 'free_lesson',
  free_test = 'free_test',
  proforientation_test = 'proforientation_test',
  chat_button = 'chat_button',
  select_institution = 'select_institution',
  admission_support = 'admission-support',
  subscription = 'subscription',
  course_program = 'course_program',
  ege_quest = 'ege_quest',
  short_course = 'short_course',
  may_marathon = 'may_marathon',
  summer_intensive = 'may_marathon',
  tutors = 'tutors',
  school_meetings = 'school_meetings',
  program_main = 'program_main',
  institution_main = 'institution_main',
  speciality_main = 'speciality_main',
  speciality_rubrics = 'speciality_rubrics',
  speciality_search = 'speciality_search',
  assessment_of_chances = 'assessment_of_chances',
  ucheba_footer = 'ucheba_footer',
  vuz_rankings = 'vuz_rankings',
  ucheba_profession_lead_form = 'ucheba_profession_lead_form',
  ucheba_search_lead_form = 'ucheba_search_lead_form',
  school_demo = 'school_demo',
}
